/* You can add global styles to this file, and also import other style files */

@use "citadel-design-system-global.scss";
@use "citadel-design-system.scss" as *;


$primary: var(--palette-primary);
$secondary: var(--neutral-500);
$white: var(--neutral-white);
$text-light: #66737e;
$text-valid: var(--status-success-default);

.cc-btn {
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: none !important;

    padding: 0 16px !important;
    border-radius: 200px;
    border: solid 1px var(--palette-primary) !important;
    background-color: var(--palette-primary) !important;
    color: var(--neutral-white) !important;


}

.cc-dismiss {
    display: flex !important;
    flex-direction: row !important;
    justify-content: center !important;
    align-items: center !important;
    height: 32px !important;
}

.cookieContainerCls {
    width: 320px !important;
    height: 263px;
    background-color: #ffffff;
    border: solid 1px #ced4da;
    border-radius: 24px;
    padding: 24px;
    box-shadow: 0 -3px 0 0 rgba(0, 0, 0, 0.07);

    .compButtonContainerCls {
        display: flex;
        width: 100%;
        justify-content: right;
        padding-top: 9px;
        height: 32px !important;
        padding-right: 7px;

        .compButtonCls {
            width: 81px !important;
        }
    }
}

.cookieMessageCls {
    font-size: 14px;
    line-height: 20px;

    .headerCls {
        height: 38px;
        font-size: 22px;
        font-weight: bold;
        line-height: 1.73;
        color: var(--neutral-dark);
        text-align: left;
    }

    .messageCls {
        padding-top: 16px;
        padding-bottom: 16px;

        .readMoreCls {
            color: var(--palette-alternate-3);
            text-decoration: underline;
        }
    }
}

.teal {
    color: var(--palette-alternate-3)
}

.unstyled {
    margin-top: 8px;
}

.unstyled li {
    display: flex;
    width: 100%;
    flex-direction: row;
    gap: 8px;
    align-items: center;
}

.unstyled li .desc {
    position: relative;
    top: -1px;
}

.unstyled li:before {
    font-size: 26px;
}

.error:before {
    content: none !important;
}

.success:before {
    content: none !important;
}

.default-text:before {
    content: "\2022" !important;
}

.error-text {
    color: dsf-colors-get($theme, danger, default)
}

.valid-text {
    color: $text-valid;
}

.match-txt {
    color: var(--status-success-default);
    margin: 0 32px;
    font-size: 11px;
    font-weight: bold;
    text-align: left;
    margin-top: 8px;
}

.default-text {
    color: $text-light;
}




.number-layout {
    display: grid;
    grid-template-columns: auto 3rem 1fr;
    grid-template-areas: "country-selection country-code-display number-input";
    width: 100%;

    .country-selection {
        grid-area: country-selection;
        min-width: 9rem;
        padding-right: 0;
        margin-right: 0;
    }


    .number-input {
        grid-area: number-input;
        padding-left: 0;
        margin-left: 0;
    }
}

.country-selector {
    width: 100px;
}

.no-otp {
    color: var(--palette-alternate-3);
    cursor: pointer;
}


.password-errors {
    color: $text-light;
}

.strengthBar {
    display: flex;
    gap: 6px;
}

.point:last-of-type {
    margin: 0 !important;
}

.point {
    background: #DDD;
    display: inline-block;
    height: 5px;
    width: 100%;
}




.success-msg {
    .icon {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 24px;
        margin-top: 329.9px;
    }

    h2 {
        align-self: stretch;
        flex-grow: 0;
        font-size: 20px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.65;
        letter-spacing: normal;
        text-align: center;
    }

    p {
        font-size: 20px;
    }
}


.validationErr {
    position: relative;
    display: block;
    background-color: var(--badge-1-tint);
    border-radius: 8px;
    height: 42px;
    width: auto;
    margin-top: 5px;
}


.validationContainer {
    position: absolute;
    margin-right: 0.25rem;
    left: 0;
    line-height: .9rem;
}

small {
    color: var(--palette-danger);
    margin: 8px;
    font-size: 11px;
    font-weight: 400;
    text-align: left;
}










.main {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-items: center;
    width: 100%;
    min-width: 312px;
    padding-top: 24px;
    //padding-right: 48px;
    padding-bottom: 24px;

    .left-hlf {
        min-width: 312px;
        max-width: 456px;
        width: 100%;

        .logo {
            width: 100%;
            display: flex;
            align-items: center;
            margin-bottom: 24px !important;

            img {
                width: 94px;
                height: 40.3px;
            }
        }

        .id-header {
            margin-bottom: 16px !important;

            .welcome {
                height: 64px;
                align-self: stretch;
                font-size: 26px !important;
                line-height: 1.23 !important;
                flex-grow: 0;
                font-size: 32px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: 1;
                letter-spacing: normal;
                text-align: left;

                .dark {
                    color: var(--neutral-dark);
                }


                .gold {
                    color: var(--palette-alternate-1);
                }
            }


            .subheader {
                margin-top: 24px;
                height: 24px;
                align-self: stretch;
                flex-grow: 0;
                font-size: 18px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.33;
                letter-spacing: normal;
                text-align: left;
                color: var(--text-dark);
            }

            .paragraph {
                margin-top: 24px;
                height: 48px;
                align-self: stretch;
                flex-grow: 0;
                font-size: 16px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.5;
                letter-spacing: normal;
                text-align: left;
                color: $text-light;
            }
        }
    }

    .right-hlf {
        display: flex;
        flex-direction: column;
        justify-items: center;
        //align-items:center;

        width: 100%;
        min-width: 312px;

        img.logo {
            width: 144px;
            height: 61.7px;
            flex-grow: 0;
            object-fit: contain;
        }

        .right-hlf-header {
            text-align: center;
            margin-bottom: 32px;
            margin-top: 16px;
        }
    }
}




@include dsm-breakpoint-down(lg) {
    .ds-app-layout{
        grid-template-columns: 1fr;
    }
}


@include dsm-breakpoint-up(md) {


    .main {
        flex-direction: row;
        justify-content: space-between;
        gap: 12px;
        max-width: 100%;
        height: 100%;
        padding-top: 66px;
        padding-right: 0;
        margin-right: 0;
        

        .left-hlf {
            width: 456px;
            display: flex;
            flex-direction: column;
            height: 100%;
            max-width: 100% !important;
          

            .logo {
                height: 15%;
                margin-bottom: 12px;

                img {
                    width: auto;
                    height: 66px;
                }
            }

            .id-header {
                justify-self: center;
                height: 85%;
                margin-top: 180.9px;
            }

        }

        .right-hlf {
            width: 100%;
            align-items: center;
            margin-bottom: 32px;
           
        }

        .content {
            width: 504px;
    
        }
    }
}

@media (min-width:600px) and (max-width:960px) {
    .left-hlf {
        max-width: 100% !important;
        width: 100%;

        .logo {
            width: 100% !important;
            display: flex !important;
            align-items: center !important;
            justify-content: center !important;
            margin-bottom: 24px !important;

            img {
                justify-self: center !important;
                //width: 204px !important;
                width: auto !important;
                height: 66px !important;

            }
        }
    }

    .right-hlf {
        align-items: center !important;

        .right-hlf-header {
            display: flex !important;
            flex-direction: column !important;
            align-items: center !important;
            width: 100%;
        }

    }
}



.mt-24 {
    margin-top: 24px
}

.mt-12 {
    margin-top: 12px
}

.mb-48 {
    margin-bottom: 48px
}

.mb-16 {
    margin-bottom: 16px;
}

.mx-32 {
    margin-left: 32px;
    margin-right: 32px;
}

.mt-32 {
    margin-top: 32px
}

.mb-32 {
    margin-bottom: 32px
}

.p-32 {
    padding: 32px;
}

.font-11 {
    font-size: 11px;
}

.cursor-pointer {
    cursor: pointer;
}

.bold {
    font-weight: bold;
}

.success-text {
    color: var(--status-success);
}


citadel-phone-number-input {
    width: 100% !important;
}

citadel-button button.ds-btn.ds-ctl-primary {
    width: 100% !important;
}

citadel-button {
    width: 100%;
}


.ds-app-layout {
    padding: 0;
}

.ds-app-content {
    padding: 0 24px;

}


.ds-app-footer {
    //width: 100vw;
    width: 100%;
    padding: 0;
    //display: flex;
    //flex-direction: column;
    //align-items: center;


}



@media screen and (max-width: 600px) {

    .ds-app-footer {
        //width: 100vw;
        width: 100%;
        padding: 0;
        display: flex;
        flex-direction: column;
        align-items: center;


    }


    .footer-banner {
        height: auto;
        display: flex;
        flex-direction: column;
        gap: 19.2px;
        font-size: 16px;
        padding: 0 24px;
        margin-bottom: 56px;
        //margin-right: 48px;
    }

    citadel-wizard-steps ul.wizard-step-container {
        width: 100% !important;
        margin-bottom: 32px !important;

        li span.title {
            display: none;
        }
    }

    li.wizard-step+li.wizard-step:after {
        width: 72% !important;
        top: 0 !important;
        margin: 15.5px 4px 15.5px 15.5px;
    }

    // .main {
    //     padding: 24px;

    //     .left-hlf {

    //         .logo {
    //             width: 100%;
    //             display: flex;
    //             align-items: center;

    //             img {
    //                 width: 94px;
    //                 height: 40.3px;
    //             }

    //         }

    //         .id-header {
    //             .welcome {
    //                 font-size: 26px !important;
    //                 line-height: 1.23 !important;
    //             }
    //         }


    //     }


    // }

    .mx-32 {
        margin-left: 16px;
        margin-right: 16px;
    }

    .container {
        padding: 0 !important;
    }

    .form-header {
        border: solid thin;
        height: 22px !important;
        align-self: stretch !important;
        flex-grow: 0 !important;
        font-size: 20px !important;
        font-weight: bold !important;
        font-stretch: normal !important;
        font-style: normal !important;
        line-height: normal !important;
        letter-spacing: normal !important;
        text-align: left !important;
        color: var(--text-dark) !important;

    }





    .form-header-extra {
        height: 100px;
        align-self: stretch;
        flex-grow: 0;
        font-size: 20px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: var(--text-dark);
    }

    .d-none-sm {
        display: none;
    }

    .radios label .label {
        margin-left: 40px;
    }



    .additional-security {
        font-size: 16px;
        text-align: left;
        color: var(--text-light);
        margin-bottom: -16px;
    }

    .Check-your-Whatsapp-for-an-OTP-one-time-PIN-and-enter-it-below {
        height: 99px;
        align-self: stretch;
        flex-grow: 0;
        font-size: 20px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.65;
        letter-spacing: normal;
        text-align: left;
        color: var(--text-dark);
        margin-bottom: -32px;
    }

    .Didnt-receive-the-OTP {
        height: 20px;
        align-self: stretch;
        flex-grow: 0;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        letter-spacing: normal;
        text-align: left;
    }

    .unstyled li {
        align-items: flex-start;
    }

    .success-msg {
        text-align: center;

        .text-light {
            font-size: 16px;
        }
    }
}



.-mt-14 {
    margin-top: -14px;
}


small {
    //color: red;
    color: dsf-colors-get($theme, danger, default);
    margin: 8px;
    font-size: 11px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
}



.invalid-feedback {
    position: relative;
    display: flex;
    background-color: var(--badge-1-tint);
    border-radius: 8px;
    height: 42px;
    width: auto;
    margin-top: 5px;
    align-items: center;

    small {
        position: absolute;
        margin-right: 0.25rem;
        left: 0;
        line-height: 0.9rem;

    }
}


.formHeader {
    align-self: stretch;
    flex-grow: 0;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: var(--text-dark);
}